const LoadingDots = ({ numberOfLoading = 4 }: { numberOfLoading?: number }) => {
  const numberOfDots = numberOfLoading; // Set the number of dots you want
  return (
    <div className="mt-2 flex items-center justify-center gap-2">
      {Array.from({ length: numberOfDots }, (_, index) => (
        <span key={index} className="loading loading-dots loading-md"></span>
      ))}
    </div>
  );
};
export default LoadingDots;
